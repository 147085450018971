import * as React from 'react';
import { ShopContact } from '../domain/interfaces.js';
import Basket from './Basket'
import CookieConsent from 'react-cookie-consent';

import {
    Svg
} from './svgIcons.js'

interface FooterLink {
    label: string
    link: string
}

interface FooterLinkGroup {
    label: string,
    links: FooterLink[]
}

const FooterLinkItem: React.FunctionComponent<{link: FooterLink}> = ({link}) => (
    <div className="group text-sm text-white transform hover:translate-x-2 cursor-pointer flex flex-row transition duration-500 ease-in-out">
      <div className="h-1 w-1 bg-white rounded-full self-center mr-2" /> <a href={link.link}> {link.label} </a>
    </div>
  )

const Footer: React.FunctionComponent<{group1: FooterLinkGroup, group2: FooterLinkGroup, group3: FooterLinkGroup, contact: ShopContact, hideBasket?: boolean}> = ({group1, group2, group3, contact, hideBasket}) => (
    <>
    { hideBasket === true || <Basket /> }
    <div className="w-full text-white bg-brand-primary-darkest">
        <div className="w-full max-w-screen-lg flex flex-col md:flex-row mx-auto px-5 py-10 md:space-x-4">

            <div className="w-full md:w-1/4">
            <div className="text-xl mb-2 text-white"> Informations </div>

            <div className="w-full flex flex-rpw mt-2"> 
                <div><div className="w-6 h-6 p-1 bg-white text-black ring-2 ring-gray-300 rounded-full"> <Svg icon="location-marker"/> </div></div>
                <div className="pl-2 text-white text-sm"> 
                {contact.address.name} <br/>
                {contact.address.line1}  <br/>
                {contact.address.line2}
                </div>
            </div>

            <div className="w-full flex flex-rpw mt-2"> 
                <div className="w-6 h-6 p-1 bg-white text-black ring-2 ring-gray-300 rounded-full"> <Svg icon="phone"/> </div>
                <div className="pl-2 text-white text-sm"> 
                {contact.phoneNumber}
                </div>
            </div>

            <div className="w-full flex flex-rpw mt-2"> 
                <div className="w-6 h-6 p-1 bg-white text-black ring-2 ring-gray-300 rounded-full"> <Svg icon="mail"/> </div>
                <div className="pl-2 text-white text-sm"> 
                {contact.emailAddress}
                </div>
            </div>
            </div>

            <div className="w-full mt-5 md:mt-0 md:w-1/4">
            <div className="text-xl mb-2 text-white"> {group1.label} </div>
                {group1.links.map((link) => <FooterLinkItem key={link.label} link={link} />)}
            </div>

            <div className="w-full mt-5 md:mt-0 md:w-1/4">
            <div className="text-xl mb-2 text-white"> {group2.label}  </div>
                {group2.links.map((link) => <FooterLinkItem key={link.label} link={link} />)}
            </div>

            <div className="w-full mt-5 md:mt-0 md:w-1/4">
            <div className="text-xl mb-2 text-white"> {group3.label} </div>
                {group3.links.map((link) => <FooterLinkItem key={link.label} link={link} />)}
            </div>
    </div>
        <div className="w-full flex flex-row text-xs pb-2 text-gray-800 bg-white text-left mt-5 pt-2 mb-0 px-10">
        <div className="text-brand-primary-darkest font-bold">
            <img alt="Région Réunion Soutient le gîte de charme l'échappée belle" src="https://cdn-static.lechappee-belle.com/img/logo-region.5b1fdfe.png" className="inline h-12 mr-4 pb-2" />
            Ce site a été développé par <a href="https://atelierdunumerique.io/" className="px-1 underline"> l'Atelier du numérique </a> 
            avec le soutien de la Région Réunion
        </div>
        </div>
    </div>
    <CookieConsent
          style={{'background':'#be4582', 'color': 'white'}}
          overlayClasses="bg-brand-primary-darkest"
          location="bottom"
          buttonText="J'accepte"
          declineButtonText="Je refuse"
          declineCookieValue="Decline"
          enableDeclineButton={true}
          declineButtonStyle={{'background':'white', 'color': '#b12d6e', 'border-radius': '5px'}}
          buttonStyle={{'background':'white', 'color': '#b12d6e', 'border-radius': '5px', 'font-weight': 'bolder'}}
          cookieName="gatsby-gdpr-google-analytics">
        <>
        <div className="font-bold text-2xl"> Acceptez-vous les cookies ?</div>
        <div> Notre site utilise des cookies afin de vous proposer des contenus et services adaptés à vos centres d'intérêts et de réaliser des statistiques anonymes de visites. Vous pouvez refuser l'utilisation de tels cookies. </div>
        </>
    </CookieConsent>
    </>
)

export default Footer