import * as React from 'react';
import { useState } from 'react';
import { ShopContact, CategoryNode, CategoryTree } from '../domain/interfaces.js';
import { Helmet } from "react-helmet"

import {
    Svg,
    SvgPhone,
    SvgMail,
    SvgChevronUp,
    SvgChevronDown,
    SvgCloseIcon,
    SvgBurgerIcon
} from './svgIcons.js'


const LgHeaderSubMenuListItem: React.FunctionComponent<{menu: CategoryNode}> = ({menu}) => (
    <div className="pt-1 text-brand-primary-darkest hover:text-brand-primary-lighter">
        <a href={`/category/${menu.slug}`}> {menu.label} </a>
     </div>
)

const LgHeaderSubCategoryTreeItem: React.FunctionComponent<{menu: CategoryNode, children: React.ReactNode}>= ({menu, children}) => (
    <div className="w-24 mt-1">
        <span className="font-bold text-black">{ menu.label }</span>
        {children}
    </div>
)
  
const LgHeaderSubCategoryTree: React.FunctionComponent<{children: React.ReactNode}> = ({children}) => (
    <div className="position z-50 absolute left-0 mt-4 py-2 px-5 bg-white shadow-lg rounded-sm hidden group-hover:block">
      <div className="w-full space-x-16 flex flex-row">
        {children}
      </div>
    </div>
)
  
const LgHeaderSubMenuList: React.FunctionComponent<{children: React.ReactNode}> = ({children}) => (
    <div className="position z-50 absolute w-full left-0 mt-5 py-2 px-5 bg-white shadow-lg rounded-sm hidden group-hover:block" style={{'minWidth': '200px'}}>
        {children}
    </div>
)
  
const LgHeaderSubMenu: React.FunctionComponent<{parentNode?: CategoryTree, menus: CategoryTree[]}> = ({parentNode, menus}) => {
  
    const hasSubSubMenu = menus.some(({children}) => children.length > 0)
  
    if (hasSubSubMenu) 
      return (
        <LgHeaderSubCategoryTree>
          {
            menus.map(({node, children}) => 
              <LgHeaderSubCategoryTreeItem key={node.label} menu={node}> 
                {children.map(({node}) => <LgHeaderSubMenuListItem key={node.label} menu={node} />)} 
              </LgHeaderSubCategoryTreeItem>)
          }
        </LgHeaderSubCategoryTree>
      )
    else
      return (
        <LgHeaderSubMenuList> 
          {parentNode && <LgHeaderSubMenuListItem key={parentNode.node.label} menu={parentNode.node} /> }
          {menus.map(({node}) => <LgHeaderSubMenuListItem key={node.label} menu={node} />)} 
        </LgHeaderSubMenuList>
      )
  
} 
  
const LgHeaderMenuItem: React.FunctionComponent<{menu: CategoryTree}> = ({menu}) => (
    <div className="group text-md py-5 px-4 hover:bg-brand-primary-lighter cursor-pointer text-white font-bold relative">
        <a href={`/category/${menu.node.slug}`} className="group-hover:text-white"> {menu.node.label} </a>
        {menu.children.length > 0 && (<LgHeaderSubMenu parentNode={menu} menus={menu.children} />)}
    </div>
)
  
  
  
const HeaderMenuLg: React.FunctionComponent<{menu: CategoryTree, contact: ShopContact}> = ({menu, contact}) => (
<div className="hidden md:block">
    <div className="w-full">
        <div>
            <a href="/"> <img className="h-32 mx-auto" src={'/chamantini/logo@xs.png'} alt="Logo" /> </a>
        </div>
    </div>
    <div className="w-full bg-brand-primary border-t border-b border-brand-gray-lightest">
        <div className="max-w-screen-lg mx-auto flex flex-row content-center justify-between">
            {menu.children.map(menu => <LgHeaderMenuItem key={menu.node.label} menu={menu}/>)}
            <div className="self-center text-white text-sm">
            <div className="w-full  flex flex-row content-center pl-10">
                <div className="self-center pr-5"> 
                    <a  href="/account" className="bg-brand-primary-lighter hover:bg-brand-primary-darkest text-white font-bold py-2 px-4 rounded flex flex-row items-center" type="a">
                        <div className="w-8 text-white"> <Svg icon="user"/>  </div>
                        <div className=""> Mon compte </div>
                    </a>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>
)
  
const XsHeaderMenuItem: React.FunctionComponent<{menu: CategoryTree}> = ({menu}) => {
const [toggled, toggle] = useState(false)
return (
    <div className="w-full text-left text-lg py-1 px-1 ">
    <div>
        <div  role="button" tabIndex={0} className={`${toggled ? 'text-white font-bold' : 'text-white'} w-full flex flex-row content-center`} onClick={() => toggle(!toggled)} onKeyDown={() => toggle(!toggled)}> 
            <div className="w-3/4"> 
               <a href={`/category/${menu.node.slug}`}>{menu.node.label}</a> 
             </div>
            <div className="w-1/4 self-center"> 
            <div className="w-5 float-right">
                { (menu.children.length) ? (toggled ? <SvgChevronUp /> : <SvgChevronDown />) : null }
            </div>
            </div>
        </div>
        <div className={`${toggled ? '' : 'hidden'} px-4`}>
            {menu.children.length > 0 && <XsHeaderMenuItem menu={{...menu, children: []}} />}
            {menu.children.map( menu => <XsHeaderMenuItem key={menu.node.label} menu={menu} /> )}
        </div>
    </div>
    </div>
)
}

const XsHeaderMenu: React.FunctionComponent<{menu: CategoryTree}> = ({menu}) => {

    let [burgerMenuIsActive, activeBurgerMenu] = useState(false)

    const BurgerMenuStyleClassNames = `w-full z-50 h-screen fixed top-20 left-0 bg-brand-primary-lighter pt-10 px-10 transition duration-500`
    const BurgerMenuDiplayClassNames = burgerMenuIsActive ? 'transform translate-x-0' : 'transform -translate-x-full' 
    const MenuButtons = menu.children.map( menu => <XsHeaderMenuItem key={menu.node.label} menu={menu}  />)

    return (
        <div className="w-full flex flex-row content-center bg-brand-primary md:hidden">
            <div className="w-1/4 self-center" />
            <div className="w-1/2 py-2 text-center">
                <a href="/"> <img className="h-20 mx-auto" src={'/chamantini/logo-white@xs.png'} alt="Logo" /> </a>
            </div>
            <div className="w-1/4 self-center pr-4">
                <div role="button" tabIndex={0} className="w-10 p-2 text-white float-right" onClick={() => activeBurgerMenu(!burgerMenuIsActive)} onKeyDown={() => activeBurgerMenu(!burgerMenuIsActive)}>
                    {burgerMenuIsActive ? <SvgCloseIcon/> : <SvgBurgerIcon/>}
                </div>
            </div>
            <div className={`${BurgerMenuStyleClassNames} ${BurgerMenuDiplayClassNames}`}>
            <div className="flex flex-col"> 
                {MenuButtons} 
                <a  href="/account" className="mt-2 bg-brand-primary hover:bg-brand-primary-darkest text-white font-bold py-2 px-4 rounded flex flex-row items-center" type="a">
                    <div className="w-8 text-white"> <Svg icon="user"/>  </div>
                    <div className=""> Mon compte </div>
                </a>
            </div>
            </div>
        </div>
    )

}
  
  
const HeaderMenu: React.FunctionComponent<{menu: CategoryTree, contact: ShopContact}> = ({menu, contact}) => {
    return (
        <>
            <XsHeaderMenu menu={menu} />
            <HeaderMenuLg menu={menu} contact={contact} />
        </>
    )
}

export default HeaderMenu