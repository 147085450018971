import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Svg } from "../components/svgIcons"
import { ShopState } from "../state/state.interface"
import * as actionCreators from "./../state/actionCreators"
import { refreshBasket, removeProductFromBasket } from "./../usecases/basket"


const connector = connect((state: ShopState) => ({
    state
}))

const aBasket: React.FunctionComponent<{state: ShopState, dispatch: any}> = ({state, dispatch}) => {

    useEffect(() => {
        dispatch(refreshBasket)
    }, [])
    
    const onDisplayBasket = () => {
        dispatch(actionCreators.Actions.displayBasket(true))
        dispatch(refreshBasket)
    }

    const onCloseBasket = () => {
        dispatch(actionCreators.Actions.displayBasket(false))
    }

    return <>
                
            <div onClick={onDisplayBasket}  className="fixed bottom-5 right-5"> 
                <button className={((state.basket.loading && !state.basket.display)? 'animate-bounce' : '')+" h-16 w-16 p-4 text-white bg-brand-primary ring-2 ring-pink-300 rounded-full hover:ring-4 shadow-lg hover:shadow-md focus:outline-none focus:ring focus:border-blue-300"}> 
                    <Svg icon='shopping-bag'/> 
                    <div className="font-bolder text-white absolute top-2 right-3"> {state.basket.products.length ? state.basket.products.length : ''} </div>
                </button>
            </div>
            
            { state.basket.display && <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    { state.basket.loading && <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50 text-brand-primary animate-spin" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                        </svg>
                    </div> }
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 className="text-lg leading-6 font-medium text-gray-900 text-brand-primary" id="modal-title">
                                    Votre Panier
                                </h3>
                                <div className="mt-5 w-full">
                                    { state.basket.loading &&  <p className="text-sm text-gray-500"> chargement du panier </p> }
                                    { state.basket.products.length == 0 && state.basket.loading == false && <p className="text-sm text-gray-500">
                                        Votre Panier est vide pour le moment
                                    </p> }
                                    { state.basket.error.length > 0 && <div className="bg-red-100 text-red-800 p-2 rounded-md my-2"> {state.basket.error} </div>}
                                    { state.basket.products.map((product) => 
                                        <div className="mb-4 w-full flex flex-row" key={product.product.id}> 
                                            <img className="h-20 w-20 rounded-sm" src={product.product.coverImage.thumbnail.imgUrl} />
                                            <div className="ml-2 w-full"> 
                                                <h1 className="text-xl -mb-1 -mt-1"> {product.quantity} {product.product.name} </h1> 
                                                <div className="text-xs text-gray-500"> {product.product.reference} </div>
                                                <div className="mt-1 text-xs"> {product.product.priceWithTaxFormat} {product.product.currencySymbol} </div>
                                                <button onClick={()=>dispatch(removeProductFromBasket(product.product.id))} className="focus:outline-none cursor-pointer float-right h-4 w-4 text-gray-800 hover:text-brand-primary"> <Svg icon="trash"></Svg> </button>
                                            </div>
                                        </div>
                                    )}
                                    {!!state.basket.priceTotalFormated && (<span> Total {state.basket.priceTotalFormated} </span>)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        { state.basket.products.length > 0 && <a href="/checkout/" type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand-primary-lighter text-base font-medium text-white hover:bg-brand-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                            Passer ma commande
                        </a> }
                        <button type="button" onClick={onCloseBasket} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                            Continuer ma visite
                        </button>
                    </div>
                    </div>
                </div>
            </div> }
    </>
}

const Basket = connector(aBasket)
export default Basket